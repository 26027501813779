import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

export const LoginLink = (props: any) => {
    return (
        <Typography variant="body1" color="text.secondary" align="center" {...props}>
            <Link
                color="inherit"
                variant='body1'
                href={process.env.REACT_APP_LOGIN_URL as string}
            >
                Prijava
            </Link>
        </Typography>
    );
}
