import React from 'react';
import {createTheme, ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {Copyright} from "./components/Copyright";
import Box from "@mui/material/Box";
import {Container} from "@mui/material";
import {LoginLink} from "./components/LoginLink";
import Typography from "@mui/material/Typography";

const defaultTheme = createTheme();

function App() {
    return (
        <ThemeProvider theme={defaultTheme}>
            <CssBaseline enableColorScheme/>
            <Box
                sx={{
                    position: 'absolute',
                    top: theme => theme.spacing(3),
                    right: theme => theme.spacing(5),
                }}
                component="header"
            >
                <LoginLink/>
            </Box>
            <Container
                component="main"
                maxWidth="md"
                sx={{
                    pt: '45vh',
                    // height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Typography
                    align='center'
                    variant='h2'
                >
                    restaurant.partners
                </Typography>
            </Container>
            <Box
                sx={{
                    position: 'absolute',
                    bottom: theme => theme.spacing(4),
                    width: '100%'
                }}
                component="footer"
            >
                <Copyright/>
            </Box>
        </ThemeProvider>
    );
}

export default App;
